@import url(https://fonts.googleapis.com/css?family=Raleway);
:root {
  --primary-color: white;
  --primary-button-color: #6c757d;
  --secondary-color: lightgray;
  --font-color: #212529;
  --bg-color: white;
  --border-color: lightgray;
  --shadow-color: rgba(42, 75, 105, 0.62);
  --header-color: #f8f9fa;
}

[data-theme="dark"] {
  --primary-color: #312385;
  --primary-button-color: #312385;
  --secondary-color: #818cab;
  --font-color: #e1e1ff;
  --bg-color: #15202b;
  --border-color: lightgray;
  --shadow-color: lightgray;
  --header-color: #101921;
  --background-card-color: 10px 10px 8px -5px rgba(42, 75, 105, 0.62);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
  overflow-x: hidden; /* Hide horizontal scrollbar */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.marged {
    margin: 4%;
    
}

.padded {
    padding: 1%;
}

.BadgeStyle {
    margin: 3px;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.cursorPointer {
    cursor: pointer;
}

.cursorPointer:hover {
    box-shadow: 10px 10px 8px -5px rgba(42, 75, 105, 0.62);
}

.margedRight {
    margin-right: 2%;
}
.center{
    font-family: 'Raleway', sans-serif;
    /* background-color: #1abc9c; */
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.card{
    background-color: #fff;
    border-radius: 15px;
    padding: 0.8rem;
}

form{
    margin-top: 2%;
    margin-bottom: 5%;
    display: flex;
    flex-direction: column;
}

.card h1{
    text-align: center;
    margin-top: 0;
    margin-bottom: 10px;
}

.form-item{
    font-family: 'Raleway', sans-serif;
    padding: 5px;
    margin-bottom: 2rem;
    height: 30px;
    width: 16rem;
    border: 1px solid grey;
}

.form-submit{
    font-family: 'Raleway', sans-serif;
    height: 35px;
    color: #fff;
    background-color: #1abc9c;
    border: none;
    letter-spacing: 0.2rem;
    transition: 0.3s opacity ease;
    cursor: pointer;
}
.form-submit:hover{
   opacity: 0.6;
}

.textAlignCenter {
    text-align: center;
}

.padded {
    padding: 5px;
}
.header {
    background-color: var(--header-color);
}

.shadowBottom {
    box-shadow: 0 2px 2px -2px var(--shadow-color);
    margin-bottom: 2px;
}

.margedLeft {
    margin-left: 1%;
}

.dropdown-menu- {
    background-color: var(--header-color);
}

.react-switch-checkbox {
    height: 0;
    width: 0;
    visibility: hidden;
}

.react-switch-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    width: 50px;
    height: 25px;
    background: grey;
    border-radius: 100px;
    position: relative;
    transition: background-color .2s;
}

.react-switch-label .react-switch-button {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: 22.5px;
    height: 22.5px;
    border-radius: 45px;
    transition: 0.2s;
    background: #fff;
    box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}

.react-switch-checkbox:checked+.react-switch-label .react-switch-button {
    left: calc(100% - 2px);
    transform: translateX(-100%);
}

.react-switch-label:active .react-switch-button {
    width: 60px;
}

.react-switch-checkbox:checked+.react-switch-label {
    background-color: #66bb6a;
}

.iconColor {
    color: var(--primary-button-color);
}
.displayFlex {
    display: flex !important;
}
