.marged {
    margin: 4%;
    
}

.padded {
    padding: 1%;
}

.BadgeStyle {
    margin: 3px;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.cursorPointer {
    cursor: pointer;
}

.cursorPointer:hover {
    box-shadow: 10px 10px 8px -5px rgba(42, 75, 105, 0.62);
}

.margedRight {
    margin-right: 2%;
}