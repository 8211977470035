@import url('https://fonts.googleapis.com/css?family=Raleway');

.center{
    font-family: 'Raleway', sans-serif;
    /* background-color: #1abc9c; */
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.card{
    background-color: #fff;
    border-radius: 15px;
    padding: 0.8rem;
}

form{
    margin-top: 2%;
    margin-bottom: 5%;
    display: flex;
    flex-direction: column;
}

.card h1{
    text-align: center;
    margin-top: 0;
    margin-bottom: 10px;
}

.form-item{
    font-family: 'Raleway', sans-serif;
    padding: 5px;
    margin-bottom: 2rem;
    height: 30px;
    width: 16rem;
    border: 1px solid grey;
}

.form-submit{
    font-family: 'Raleway', sans-serif;
    height: 35px;
    color: #fff;
    background-color: #1abc9c;
    border: none;
    letter-spacing: 0.2rem;
    transition: 0.3s opacity ease;
    cursor: pointer;
}
.form-submit:hover{
   opacity: 0.6;
}

.textAlignCenter {
    text-align: center;
}

.padded {
    padding: 5px;
}