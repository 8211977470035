:root {
  --primary-color: white;
  --primary-button-color: #6c757d;
  --secondary-color: lightgray;
  --font-color: #212529;
  --bg-color: white;
  --border-color: lightgray;
  --shadow-color: rgba(42, 75, 105, 0.62);
  --header-color: #f8f9fa;
}

[data-theme="dark"] {
  --primary-color: #312385;
  --primary-button-color: #312385;
  --secondary-color: #818cab;
  --font-color: #e1e1ff;
  --bg-color: #15202b;
  --border-color: lightgray;
  --shadow-color: lightgray;
  --header-color: #101921;
  --background-card-color: 10px 10px 8px -5px rgba(42, 75, 105, 0.62);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
